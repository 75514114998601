let PAGES = 1;
const IMAGES_PER_PAGE = 15;

document.getElementById("btn_recent").addEventListener("click", (event) => {
  updatePortfolioHeadline("These are my latest photos");
  updateGallery("recent");
  hideWelcomeMessage(false);
});

document.getElementById("btn_prev").addEventListener("click", (event) => {
  showPrevPage();
});

document.getElementById("btn_next").addEventListener("click", (event) => {
  showNextPage();
});

document.addEventListener("keydown", (event) => {
  if (event.key === 'ArrowRight') {
    showNextPage();
  }
  if (event.key === 'n') {
    showNextPage();
  }
  if (event.key === 'ArrowLeft') {
    showPrevPage();
  }
  if (event.key === 'p') {
    showPrevPage();
  }
});

updateGallery("recent");

function showNextPage() {
  let currentPage = getCurrentPage();
  if (currentPage < PAGES) {
    setCurrentPage(++currentPage);
    updateGallery("recent");
  }
}

function showPrevPage() {
  let currentPage = getCurrentPage();
  if (currentPage > 1) {
    setCurrentPage(--currentPage);
    updateGallery("recent");
  }
}

function getCurrentPage() {
  let page = sessionStorage.getItem("current_page");
  return page === null ? 1 : Number(page);
}

function setCurrentPage(value) {
  sessionStorage.setItem("current_page", value);
}

function updatePortfolioHeadline(text) {
  let h2 = document.getElementById("portfolio_headline");
  h2.textContent = text;
}

function updatePageCounter() {
  let div = document.getElementById("page_counter");
  div.textContent = getCurrentPage() + " / " + PAGES;
}

function hideWelcomeMessage(hidden) {
  let div = document.getElementById("welcome_message");
  div.hidden = hidden;
}

async function updateGallery(name) {
    let response = await fetch("./json/" + name + '.json');
    let jsonData = await response.json();
  jsonData.reverse();
  PAGES = Math.ceil(jsonData.length / IMAGES_PER_PAGE);
  updatePageCounter();
  displayImages(jsonData);
}

function displayImages(jsonData) {
  let container = document.getElementById("gallery");
  let from = Math.max((getCurrentPage() - 1) * IMAGES_PER_PAGE, 0);
  let to = from + IMAGES_PER_PAGE;

  if (container.childNodes.length === 0) {
    for (let i = from; i < to; i++) {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = jsonData[i].file;
      a.setAttribute("data-pswp-width", jsonData[i].width);
      a.setAttribute("data-pswp-height", jsonData[i].height);
      a.setAttribute("data-pswp-srcset", jsonData[i].srcset);
      let img = document.createElement("img");
      img.src = jsonData[i].thumbnail;
      img.alt = jsonData[i].createdate;
      a.appendChild(img);
      let caption = createCaption(jsonData[i]);
      a.appendChild(caption);
      container.appendChild(a);
    }
  } else {
    let nodeNumber = 0;
    for (let i = from; i < to; i++) {
      let a = container.childNodes[nodeNumber++];
      a.href = jsonData[i].file;
      a.setAttribute("data-pswp-width", jsonData[i].width);
      a.setAttribute("data-pswp-height", jsonData[i].height);
      a.setAttribute("data-pswp-srcset", jsonData[i].srcset);
      let img = a.firstChild;
      img.src = jsonData[i].thumbnail;
      img.alt = jsonData[i].createdate;
      a.removeChild(a.lastChild);
      let caption = createCaption(jsonData[i]);
      a.appendChild(caption);
    }
  }
}

function createCaption(json) {
  let caption = document.createElement("span");
  caption.className = "pswp-caption-content";

  let table = document.createElement("table");
  table.className = "exif-table";

  appendCaptionTableProperty(table, "Photo was taken", json.createdate);
  appendCaptionTableProperty(table, "Body", json.make + " " + json.cameramodelname);
  appendCaptionTableProperty(table, "Lens", json.lensmodel );
  appendCaptionTableProperty(table, "Focal length 35 mm equivalent", json.focallengthin35mmformat);
  appendCaptionTableProperty(table, "Aperture", "f" + json.aperturevalue);
  appendCaptionTableProperty(table, "Exposure", json.exposuretime + " sec");
  appendCaptionTableProperty(table, "ISO", json.iso);
  if (json.flash === "No Flash" || json.flash === "Off, Did not fire") {
    appendCaptionTableProperty(table, "Flash used", "No");
  } else {
    appendCaptionTableProperty(table, "Flash used", "Yes");
  }
  appendCaptionTableProperty(table, "Copyright informationen", json.copyright );

  caption.appendChild(table);
  return caption;
}

function appendCaptionTableProperty( table, label, value) {
  let tr = document.createElement("tr");
  let th = document.createElement("th");
  th.textContent = label;
  tr.appendChild(th);
  table.appendChild(tr);
  tr = document.createElement("tr");
  let td = document.createElement("td");
  td.textContent = value;
  tr.appendChild(td);
  table.appendChild(tr);
}